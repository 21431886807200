import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import Image from '@/components/Image'
import DefaultLink from '@/components/DefaultLink'
import placeholder from './placeholder.png'

export interface TasteDate {
  heading?: string
  link: string
  alt?: string
  focus?: string
  img: any
  frontpage?: any
}

const Taste = ({ heading, link, img, alt, focus, frontpage = false }: TasteDate) => {
  const image: any = getImage(img) || null

  const textClass = frontpage
    ? 'flex-grow flex text-center justify-center items-center flex-col'
    : 'flex-grow flex text-center justify-center items-center flex-col'
  const elemStyle = frontpage ? 'w-[90%] sm:w-[220px]' : ''

  return (
    <DefaultLink to={link} className={`${elemStyle} grow-0 shrink-0 box-border text-center my-4 m-4 flex flex-col`}>
      <div>
        {img ? (
          <Image className="mx-6 sm:mx-0" image={image} alt={alt ? alt : 'Jäätelö / Ice cream'} />
        ) : (
          <div className="mx-6 sm:mx-0">
            <Image image={placeholder} alt={alt ? alt : 'Jäätelö / Ice cream'} />
          </div>
        )}
      </div>
      <div className={`mt-3 ${textClass}`}>
        <h3 className="mt-auto text-smallheading">{heading}</h3>
        {focus && <p className="text-md">{focus}</p>}
      </div>
    </DefaultLink>
  )
}

export default Taste
